<script lang="ts">
import { Term, TermItemDefinition } from '@/services/term'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'baseSearch',
  data() {
    return {
      searchCardStore: null //pass the childs Store to the parent
    }
  },
  emits: ['search'],
  computed: {
    /* ! Trap ,when you acces termItems from another compontnes computed Prop it will not be bound reactive
     */
    _termItems(): TermItemDefinition[] {
      if (!this.searchCardStore) return []
      return Term.buildTermItems(this.searchCardStore, this.searchCardStore.items)
    }
    /* params are often mapped in a unpredictable way like:
     dairyIdentIn: useSearchSegmentationRelationStore().SegmentationRelation_identin,
     */
  },
  methods: {
    // tell the parent template to trigger a search
    search() {
      //this.$parent.$emit('search','wtf')
      this.$emit('search')
    },
    setSearchCardStore(store: any, initFromQuery: boolean) {
      this.searchCardStore = store
      if (initFromQuery) {
        this.initSearchStoreFromQuery(this.searchCardStore, this.$route.query)
      }
    },
    reset() {
      if (this.searchCardStore) {
        this.resetFields(this.searchCardStore)
      }
    },
    resetFields(searchCardStore: any) {
      /*
       here you can initialize the store, but this leads to a reloading of the fetched list-objects
       eg: this.$store.state.SearchLegalEntityStore.reset()

      this option reset only known fields from items[]
       */

      //reset all the fields
      for (const item of searchCardStore.items) {
        this.resetOneField(searchCardStore, item)
      }
    },
    resetOneField(searchCardStore: any, item: TermItemDefinition) {
      if (item?.defaultValue) {
        searchCardStore[item.key] = item.defaultValue
      } else if (item?.itemType == 'objects') {
        searchCardStore[item.key].map((atrX) => {
          atrX.value = null
        })
      } else {
        searchCardStore[item.key] = null
      }
    },
    initSearchStoreFromQuery(searchCardStore: any, query: any) {
      if (Object.keys(query).length == 0) {
        return 'do not reset fields on when no params are set'
      }

      let termOrQueryItems = searchCardStore?.useTerms === true ? Term.parse(this.$route.query.term) : query

      for (const item of searchCardStore.items) {
        if (item?.itemType == 'objects') {
          searchCardStore[item.key].map((atrX) => {
            const nameToFind = `${item.key}_${atrX.id}`
            if (termOrQueryItems === '' || termOrQueryItems === undefined) {
              atrX.value = ''
            } else {
              atrX.value = termOrQueryItems.hasOwnProperty(nameToFind) ? termOrQueryItems[nameToFind] : null
            }
          })
          continue
        }

        if (item.key === 'Dairy_ident') {
          let a = 0 // for debugging proposes
        }
        // ToDo: item-type auf den Metadaten in den stores hinterlegen
        // Bsp.: { key: 'Farm_agisId', operation: ':', type: 'integer' }

        let itemType = 'string'
        if (item?.itemType) {
          itemType = item.itemType
        } else if (
          item.key.substring(item.key.length - 2) === 'Id' &&
          item.key.substring(item.key.length - 3) !== 'uid' &&
          item.key.substring(item.key.length - 4) !== 'egid' &&
          item.key.substring(item.key.length - 10) !== 'cantonalId'
        ) {
          itemType = 'integer'
        }
        if (termOrQueryItems === undefined || !termOrQueryItems.hasOwnProperty(item.key)) {
          // is key defined in url?
          searchCardStore[item.key] = null
        } else if (termOrQueryItems[item.key] === null) {
          //does the url key has a value
          searchCardStore[item.key] = null
        } else if (['integer', 'number'].includes(itemType)) {
          // is it numeric
          searchCardStore[item.key] = parseInt(termOrQueryItems[item.key])
        } else if ('boolean' === itemType) {
          //is it a boolean
          searchCardStore[item.key] = termOrQueryItems[item.key] == 'true'
        } else if (item?.isList) {
          //is it a list
          searchCardStore[item.key] = termOrQueryItems[item.key].toString().replace(/\|/g, ',')
        } else {
          // its something else
          searchCardStore[item.key] = termOrQueryItems[item.key]
        }
      }
    }
  }
})
</script>
